import { NavigateFunction } from 'react-router-dom';
import { IntroProgressLayout } from '@modules/encoding/shared/components/IntroProgressLayout/IntroProgressLayout';
import { HelpBox, Stack } from '@soil-capital/ui-kit/components';
import { IntroProgressItem } from '@modules/encoding/shared/components/IntroProgressItem/IntroProgressItem';
import { ContentPage } from '@modules/encoding/shared/components/ContentPage/ContentPage';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useIntroductionContentData } from './useIntroductionContentData';
import { DrainageFieldSelectionModal } from '../../components/DrainageFieldSelectionModal/DrainageFieldSelectionModal';
import { EncodingDoneButton } from '@modules/encoding/shared/components/EncodingDoneButton/EncodingDoneButton';

export const IntroductionContentPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();

    const {
        harvestYear,
        isDataLoading,
        maxDrainageDefinitionProgress,
        currentDrainageDefinitionProgress,
        handleContinueClicked,
        closeDrainageFieldSelectionModal,
        isDrainageFieldSelectionModalOpen,
        openDrainageFieldSelectionModal,
    } = useIntroductionContentData({ navigate });

    return (
        <ContentPage
            Title={<ContentPage.Title>{t('encoding-drainage.introduction.title', { harvestYear })}</ContentPage.Title>}
            Image={<ContentPage.Image src="/assets/images/farm-tractor-field.webp" />}
            Action={
                <EncodingDoneButton
                    fallbackButton={
                        <ContentPage.Action onClick={handleContinueClicked}>
                            {t('constants.continue')}
                        </ContentPage.Action>
                    }
                />
            }
            isLoading={isDataLoading}
        >
            <IntroProgressLayout
                title={t('encoding-drainage.introduction.progress-title')}
                leftSection={
                    <Stack spacing={1} mt={1}>
                        <IntroProgressItem
                            text={t('encoding-drainage.introduction.drainage-field-definition-progress')}
                            progress={{
                                max: maxDrainageDefinitionProgress,
                                current: currentDrainageDefinitionProgress,
                            }}
                            onClick={openDrainageFieldSelectionModal}
                        />
                    </Stack>
                }
                rightSection={
                    <HelpBox>
                        <Typography variant="h3">{t('encoding-drainage.introduction.help-title')}</Typography>

                        {t('encoding-drainage.introduction.help-subtitle-1', '') && (
                            <Typography variant="body" component="h4" mt={2}>
                                {t('encoding-drainage.introduction.help-subtitle-1')}
                            </Typography>
                        )}
                        {t('encoding-drainage.introduction.help-description-1', '') && (
                            <Typography variant="subcaption" color="neutral">
                                {t('encoding-drainage.introduction.help-description-1')}
                            </Typography>
                        )}
                    </HelpBox>
                }
            />
            <DrainageFieldSelectionModal
                open={isDrainageFieldSelectionModalOpen}
                onClose={closeDrainageFieldSelectionModal}
            />
        </ContentPage>
    );
};

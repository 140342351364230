import { Item } from './GraphBuilder/graphBuilder.types';

export const formatBarGraphData = (data: { [category: string]: { [item: string]: Item } }) => {
    const categories = Object.keys(data);

    const formattedData: { [category: string]: { [item: string]: number } } = {};
    const itemNames: { [category: string]: { [item: string]: string } } = {};

    Object.keys(data).forEach((category) => {
        formattedData[category] = {};
        itemNames[category] = {};

        Object.keys(data[category]).forEach((item) => {
            const entry = data[category][item];

            if (typeof entry === 'number') {
                formattedData[category][item] = entry;
            } else {
                formattedData[category][item] = typeof entry.value === 'string' ? parseFloat(entry.value) : entry.value;
                itemNames[category][item] = entry.name;
            }
        });
    });
    const itemsSet = new Set<string>();
    categories.forEach((category) => {
        const items = Object.keys(formattedData[category]);
        items.forEach((item) => itemsSet.add(item));
    });
    const items = Array.from(itemsSet);

    const itemsNames = new Set<string>();
    categories.forEach((category) => {
        const items = Object.values(itemNames[category]);
        items.forEach((item) => itemsNames.add(item));
    });
    const names = Array.from(itemsNames);

    const sumStackValues = categories.map((category) => {
        const values = Object.values(formattedData[category]).filter((val): val is number => typeof val === 'number');
        return values.reduce((acc, val) => acc + val, 0);
    });
    const globalMaxStackValue = Math.max(...sumStackValues) < 0 ? 0 : Math.max(...sumStackValues);
    const globalMinStackValue = Math.min(...sumStackValues) > 0 ? 0 : Math.min(...sumStackValues);
    const yAxisMax = Math.ceil(globalMaxStackValue - Math.ceil(globalMinStackValue / 5));
    const yAxisMin = Math.ceil(globalMinStackValue - Math.ceil(globalMaxStackValue / 5));

    const lastPositiveItemPerCategory: { [category: string]: string | null } = {};
    const lastNegativeItemPerCategory: { [category: string]: string | null } = {};

    categories.forEach((category) => {
        const items = Object.keys(formattedData[category]);
        let lastPositiveItem: string | null = null;
        let lastNegativeItem: string | null = null;

        items.forEach((item) => {
            const value = formattedData[category][item];
            if (value > 0) {
                lastPositiveItem = item;
            } else if (value < 0) {
                lastNegativeItem = item;
            }
        });

        lastPositiveItemPerCategory[category] = lastPositiveItem;
        lastNegativeItemPerCategory[category] = lastNegativeItem;
    });

    return {
        categories,
        items,
        formattedData,
        names,
        yAxisMax,
        yAxisMin,
        lastPositiveItemPerCategory,
        lastNegativeItemPerCategory,
    };
};

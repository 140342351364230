import { makeStyles } from '@soil-capital/ui-kit/style';

export const useResultsCalculationDetailStyles = makeStyles()((theme) => ({
    container: {
        padding: `${theme.spacing(3)} ${theme.spacing(1.5)}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'stretch',
    },
    calculDetail: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    calculDetailIcon: {
        color: theme.palette.darkScale[700],
    },
    flexbox: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        justifyContent: 'space-between',
        padding: `0 ${theme.spacing(1)}`,
    },
    heading: {
        fontSize: theme.typography.h3.fontSize,
        textAlign: 'center',
    },
    ghg: {
        paddingBottom: theme.spacing(3),
    },
    description: { paddingBottom: theme.spacing(4) },
    HeadingContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
    },
}));

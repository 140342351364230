import { useGetNextFarmSeasonQuery } from '@modules/yoy/api/yoyApi';
import { useIsNextStaticSeasonOpened } from './useIsNextStaticSeasonOpened';
import { useGetPartnerInfoQuery } from '@modules/billing/api/partnerApi';
import { useGetFarmer } from './useGetFarmer';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export const useCanOpenNewSeason = () => {
    const { farmer } = useGetFarmer();
    const { currentSeason, currentSeasonLoading } = useCurrentSeason();
    const { currentSeasonId } = useCurrentSeasonId();
    const isNextSeasonOpened = useIsNextStaticSeasonOpened();

    const { data: nextFarmSeason, isLoading: isLoadingNextFarmSeason } = useGetNextFarmSeasonQuery({
        farmSeasonId: currentSeasonId,
    });
    const { data: partnerInfo, isLoading: isPartnerInfoLoading } = useGetPartnerInfoQuery({ userId: farmer?.id });

    const isEncodingFinalized = !!currentSeason?.has_results_published;
    const userHasPaidHisNewSeason =
        !!nextFarmSeason?.latest_farm_season_product_plan?.paid_at ||
        nextFarmSeason?.latest_farm_season_product_plan?.product_plan?.name === 'NETOFF' ||
        nextFarmSeason?.latest_farm_season_product_plan?.status === 'pending';
    const isPartnerPrepaidAndIsSummaryValidated = !!(
        partnerInfo?.is_partner_prepaying && currentSeason?.summary_validated_at
    );

    const canOpenNewSeason =
        isNextSeasonOpened &&
        !nextFarmSeason?.season_id &&
        ((isEncodingFinalized && !!nextFarmSeason && userHasPaidHisNewSeason) || isPartnerPrepaidAndIsSummaryValidated);

    return {
        canOpenNewSeason,
        isLoading: isLoadingNextFarmSeason || isPartnerInfoLoading || currentSeasonLoading,
    };
};

import * as echarts from 'echarts';
import { useRef, useEffect, useState, useMemo } from 'react';
import { FarmSeasonResultsV3T, carbonUnits } from '@modules/results/api/results.types';
import { addGrassAnd0 } from '@modules/results/utils/addGrassAnd0';
import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { resultsBarGraphBuilder } from '@modules/results/utils/GraphBuilder/resultsBarGraphBuilder';
import { useTranslation } from 'react-i18next';

export const useCarbonResultsGraph = ({ results }: { results: FarmSeasonResultsV3T }) => {
    const carbonResultsGraphRef = useRef(null);
    const [selectedUnit, setSelectedUnit] = useState<carbonUnits.TCO2 | carbonUnits.TCO2HA>(carbonUnits.TCO2HA);
    const { t } = useTranslation();

    const ghgValue = useMemo(
        () =>
            selectedUnit === carbonUnits.TCO2HA
                ? Number(results?.ghg_balance_average.toFixed(2))
                : Number(results?.ghg_balance.toFixed(2)),
        [results?.ghg_balance, results?.ghg_balance_average, selectedUnit],
    );

    const { currentFarm } = encodingShared.useCurrentFarm();
    const { seasonState } = entities.season.useState({ farmId: currentFarm?.id }, { skip: !currentFarm?.id });

    const seasons = seasonState?.list;

    const getSeasonNameByYear = (year: -2 | -1 | 0) => {
        return seasons.find((season) => season.carbon_programme_year === year)?.harvest_year;
    };

    useEffect(() => {
        if (carbonResultsGraphRef.current && results) {
            const carbonResultGraph = echarts.init(
                carbonResultsGraphRef.current,
                { color: ['#D89C58'] },
                {
                    renderer: 'svg',
                },
            );
            const formattedData: { [category: string]: { [item: string]: number } } = {
                [t('results.earning.graph.x-axis')]: {
                    [t('results.earning.graph.sequestration-legend', {
                        storageOrEmission:
                            results.ghg_balance > 0 ? t('constants.emission') : t('constants.sequestration'),
                    })]: ghgValue,
                },
            };

            const options = resultsBarGraphBuilder({
                data: formattedData,
                tooltipTitle: t('results.earning.graph.x-axis'),
                tooltipUnit: selectedUnit === carbonUnits.TCO2HA ? t('constants.TCO2-ha') : t('constants.total-TCO2'),
            });
            options.series.forEach((serie) => {
                serie.barWidth = '41px';
            });
            carbonResultGraph.setOption(options);
            addGrassAnd0(carbonResultGraph);
            return () => {
                carbonResultGraph.dispose();
            };
        }
    }, [ghgValue, results, results.ghg_balance, selectedUnit, t]);

    return { carbonResultsGraphRef, ghgValue, getSeasonNameByYear, setSelectedUnit, selectedUnit };
};

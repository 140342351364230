/** this date match the terms and conditions files names (/public/terms-and-conditions). If we add newer files, we should update this */
export const CONTRACT_DATE = '06_20_2024' as const;
export const CONTRACT_ANNEX_DATE = '24_01_2025' as const;

type TaCFilesMap = {
    [country: string]: {
        [language: string]: string;
    };
};

export const TAC_FILES: TaCFilesMap = {
    BE: {
        fr: `${CONTRACT_DATE}-be-fr.pdf`,
        nl: `${CONTRACT_DATE}-be-nl.pdf`,
    },
    FR: {
        fr: `${CONTRACT_DATE}-fr-fr.pdf`,
    },
    GB: {
        en: `${CONTRACT_DATE}-gb-en.pdf`,
    },
    IE: {
        en: `${CONTRACT_DATE}-ie-en.pdf`,
    },
};

export const ANNEX_FILES: TaCFilesMap = {
    BE: {
        fr: `${CONTRACT_ANNEX_DATE}-be-fr-annex3.pdf`,
        nl: `${CONTRACT_ANNEX_DATE}-be-nl-annex3.pdf`,
    },
    FR: {
        fr: `${CONTRACT_ANNEX_DATE}-fr-fr-annex3.pdf`,
    },
    GB: {
        en: `${CONTRACT_ANNEX_DATE}-gb-en-annex3.pdf`,
    },
    IE: {
        en: `${CONTRACT_ANNEX_DATE}-ie-en-annex3.pdf`,
    },
};

import { useGraphContainerStyles } from './GraphContainer.style';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { ReactNode } from 'react';
import { PieChartGraphSkeleton } from './PieChartGraphSkeleton/PieChartGraphSkeleton';
import { StackBarGraphSkeleton } from './StackBarGraphSkeleton/StackBarGraphSkeleton';
import { NoDataFound } from './NoDataFound/NoDataFound';

export const GraphContainer = ({
    title,
    titleIcon,
    children,
    hasBorders = false,
    isLoading = false,
    type = 'bar',
    className,
    hasNoData,
    titleEnd,
    orientation,
}: {
    title: string;
    titleIcon?: ReactNode;
    children: ReactNode;
    hasBorders?: boolean;
    isLoading?: boolean;
    type?: 'pie' | 'bar';
    className?: string;
    hasNoData?: boolean;
    titleEnd?: ReactNode;
    orientation?: 'horizontal' | 'vertical';
}) => {
    const { classes, cx } = useGraphContainerStyles({ hasBorders, isLoading });
    return (
        <div className={cx(classes.container, className)}>
            <div className={classes.title}>
                {titleIcon && titleIcon}
                <Typography variant="h3">{title}</Typography>
                <div className={classes.titleEnd}>{titleEnd ?? titleEnd}</div>
            </div>
            {isLoading && (type === 'bar' ? <StackBarGraphSkeleton /> : <PieChartGraphSkeleton />)}
            {hasNoData && !isLoading ? (
                <NoDataFound orientation={orientation} />
            ) : (
                <div className={classes.children}>{children}</div>
            )}
        </div>
    );
};

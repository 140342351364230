import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import { farmProfileRoutesConfig } from '@modules/encoding/modules/farmProfile/farmProfile.routes';
import { FarmProfileActionPage } from '@modules/encoding/modules/farmProfile/pages/FarmProfileActionPage/FarmProfileActionPage';
import { FarmProfileContextualPanel } from '@modules/encoding/modules/farmProfile/pages/FarmProfileContextualPanel/FarmProfileContextualPanel';
import { encodingShared } from '@modules/encoding/shared';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { NOT_FOUND_ROUTE } from '@shared/errors';
import { Guard } from '@shared/guards/Guard';
import { ActionPageSkeleton } from '@modules/encoding/shared/components/ActionPage/ActionPageSkeleton';
import { IsStepVisibleGuard } from '@shared/guards/IsStepVisibleGuard';

export default () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const navigate = useNavigate();

    const routes = useRoutes([
        {
            path: farmProfileRoutesConfig.base,
            element: (
                <Guard
                    loader={<ActionPageSkeleton />}
                    element={<FarmProfileContextualPanel />}
                    guards={[IsStepVisibleGuard]}
                />
            ),
            children: [
                {
                    path: farmProfileRoutesConfig.base,
                    element: <FarmProfileActionPage navigate={navigate} />,
                },
            ],
        },
        { path: '*', element: <Navigate to={`/${currentSeasonId}${NOT_FOUND_ROUTE}`} /> },
    ]);

    return (
        <encodingShared.FadeTransition transitionKey={routes?.props?.match?.pathnameBase as string | undefined}>
            {routes}
        </encodingShared.FadeTransition>
    );
};

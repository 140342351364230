import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { useFarmProfileProgress } from '@modules/encoding/modules/farmProfile/pages/FarmProfileActionPage/useFarmProfileProgress';
import { encodingShared } from '@modules/encoding/shared';
import { HelpBox } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import { FarmProfileForm } from '../../components/FarmProfileForm/FarmProfileForm';
import { useFarmProfileActionPageStyles } from './FarmProfileActionPage.style';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';
import { FarmProfileCommentForm } from '@modules/encoding/modules/farmProfile/components/FarmProfileCommentForm/FarmProfileCommentForm';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useGetFarmSeasonProfileQuery } from '@modules/encoding/modules/farmProfile/api/farmProfileApi';
import { FarmProfileFormSkeleton } from '@modules/encoding/modules/farmProfile/components/FarmProfileForm/FarmProfileFormSkeleton';
import { useIsEncodingDone } from '@shared/hooks/useIsEncodingDone';

export const FarmProfileActionPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { readOnly } = useFarmSeasonReadOnly();
    const { classes } = useFarmProfileActionPageStyles();
    const { t } = useTranslation();
    const { isLoading } = useGetFarmSeasonProfileQuery({ farmSeasonId: currentSeasonId });
    const { canContinue } = useFarmProfileProgress({ farmSeasonId: currentSeasonId });
    const { isEncodingDone } = useIsEncodingDone();

    const handleBackClick = () => navigate(`../${encodingRoutesConfig.PREPARATION}`);
    const handleGoNext = () =>
        isEncodingDone
            ? navigate(`../${encodingRoutesConfig.ENCODING_DONE}`)
            : navigate(`../${encodingRoutesConfig.ROTATION}`);

    return (
        <encodingShared.ActionPage
            title={t('encoding-farm-profile.action-page.title')}
            leftButtonProps={{
                onClick: handleBackClick,
                children: t('constants.back'),
            }}
            rightButtonProps={{
                disabled: !canContinue,
                loading: isLoading,
                onClick: handleGoNext,
                children: t('constants.continue'),
            }}
            readOnly={readOnly}
        >
            <main className={classes.container}>
                <HelpBox>{t('encoding-farm-profile.help-box')}</HelpBox>
                {isLoading ? (
                    <FarmProfileFormSkeleton />
                ) : (
                    <>
                        <FarmProfileForm />
                        <FarmProfileCommentForm />
                    </>
                )}
            </main>
        </encodingShared.ActionPage>
    );
};

import { useEffect, useMemo, useRef } from 'react';
import * as echarts from 'echarts';
import { useTranslation } from 'react-i18next';
import { useGetTillagePracticeQuery } from '@modules/results/api/summary.api';
import { SharedStateT } from '@shared/store';
import { useSelector } from 'react-redux';
import { tillagePracticeGraphTheme } from '@modules/results/utils/theme/tillagePracticeGraphTheme';
import { useGetSelectedHarvestYear } from '@modules/results/hooks/useGetSelectedHarvestYear';
import { summaryBarGraphBuilder } from '@modules/results/utils/GraphBuilder/summaryBarGraphBuilder';

export const useTillagePracticeGraphGraphs = () => {
    const tillagePracticeGraphRef = useRef(null);
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSummarySeason.selectedSeasonId);
    const { t } = useTranslation();
    const { selectedHarvestYear, selectedHarvestYearLoading } = useGetSelectedHarvestYear();
    const {
        data: tillagePracticeData,
        isLoading: isLoadingTillagePractice,
        isFetching: isFetchingTillagePractice,
    } = useGetTillagePracticeQuery({
        farmSeasonId: selectedSeasonId,
    });

    useEffect(() => {
        if (tillagePracticeData && tillagePracticeGraphRef.current) {
            const tillagePracticeGraph = echarts.init(tillagePracticeGraphRef.current, tillagePracticeGraphTheme, {
                renderer: 'svg',
            });
            const formattedData: Record<string, Record<string, number>> = {
                [t('constants.harvest-year', { harvestYear: selectedHarvestYear })]: tillagePracticeData.reduce(
                    (acc, item) => {
                        acc[t(`${item.slug}`)] = parseFloat(Number(item.total_area).toFixed(2));
                        return acc;
                    },
                    {} as Record<string, number>,
                ),
            };
            const option = summaryBarGraphBuilder({
                data: formattedData,
                yTitle: t('results.summary.emissions.tillage-practice-y-axis-legend'),
                hasLegend: true,
                hasTooltip: true,
                tooltipTitle: t('results.summary.emissions.tillage-practice-title'),
                tooltipUnit: t('constants.hectare-unit'),
            });
            option.grid.right = '55%';

            tillagePracticeGraph.setOption(option);
            return () => {
                tillagePracticeGraph.dispose();
            };
        }
    }, [tillagePracticeData, selectedHarvestYear, t]);

    const hasNoData = useMemo(
        () =>
            tillagePracticeData?.length === 0 &&
            !isLoadingTillagePractice &&
            !selectedHarvestYearLoading &&
            !isFetchingTillagePractice,
        [isFetchingTillagePractice, selectedHarvestYearLoading, isLoadingTillagePractice, tillagePracticeData],
    );

    return {
        tillagePracticeGraphRef,
        hasNoData,
        isLoadingTillagePractice: isLoadingTillagePractice || selectedHarvestYearLoading || isFetchingTillagePractice,
    };
};

export const createHatchPattern = () => {
    const canvas = document.createElement('canvas');
    const size = 8;
    canvas.width = size;
    canvas.height = size;

    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return;
    }
    ctx.strokeStyle = '#F0DAC0';
    ctx.lineWidth = 1;

    ctx.beginPath();
    ctx.moveTo(size, 0);
    ctx.lineTo(0, size);
    ctx.stroke();

    const img = new Image();
    img.src = canvas.toDataURL();
    return img;
};

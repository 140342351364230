import { IconGraph, IconPremium } from '@soil-capital/ui-kit/icons';
import { Typography, useTheme } from '@soil-capital/ui-kit/material-core';
import { useNextStepsStyle } from './NextSteps.style';
import { useTranslation } from 'react-i18next';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetActiveSeason } from '@shared/hooks/useGetLatestActiveSeason';

export type NextStepsPropsT = {
    displayCertificatesSection: boolean;
};

export default ({ displayCertificatesSection }: NextStepsPropsT) => {
    const { t } = useTranslation();
    const { classes } = useNextStepsStyle();
    const theme = useTheme();
    const { currentSeason, currentSeasonLoading } = useCurrentSeason();
    const { activeSeason, isLoading: isLoadingActiveSeason } = useGetActiveSeason(currentSeason?.farm_id);
    const currentYear = activeSeason?.harvest_year && activeSeason.harvest_year + 1;

    return (
        <div className={classes.nextSteps}>
            <Typography variant="caps" color={theme.palette.primaryScale[300]}>
                {t('next-steps.title')}
            </Typography>
            <div className={classes.contentFrame}>
                <div className={classes.agroSection}>
                    <div className={classes.profilePicture}></div>
                    <div className={classes.agroText}>
                        <Typography variant="caption" color={theme.palette.darkScale[50]}>
                            {t('next-steps.agro.title')}
                        </Typography>
                        <Typography variant="caption" color={theme.palette.primaryScale[400]}>
                            {t('next-steps.agro.text')}
                        </Typography>
                    </div>
                </div>
                <div className={classes.resultsSection}>
                    <div className={classes.resultsIcon}>
                        <IconGraph />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="caption" color={theme.palette.darkScale[50]}>
                            {t('next-steps.carbon-results.title')}
                        </Typography>
                        <Typography variant="caption" color={theme.palette.primaryScale[400]}>
                            {t('constants.months.may')}
                            {!currentSeasonLoading && !isLoadingActiveSeason && <span> {currentYear}</span>}
                        </Typography>
                    </div>
                </div>
                {displayCertificatesSection ? (
                    <div data-testid="certificates-section" className={classes.certificatesSection}>
                        <div className={classes.certificatesIcon}>
                            <IconPremium />
                        </div>
                        <Typography variant="caption" color={theme.palette.darkScale[50]}>
                            Certificats carbone
                        </Typography>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export type FarmSeasonResultsV3T = {
    id: number;
    farm_season_id: number;
    livestock_emissions: number;
    crop_net_sequestrations: number;
    crop_net_emissions: number;
    crop_net_ghg_balance: number;
    crop_net_ghg_balance_potential: number;
    ghg_balance: number;
    ghg_balance_average: number;
    area_total: number;
    area_non_permanent_crops: number;
};

export type FarmSeasonResultsPerCropV3T = {
    id: number;
    slug: string;
    name: string;
    total_ghg_balance: number;
    total_sequestrations: number;
    total_emissions: number;
    total_area: number;
}[];

export enum carbonUnits {
    TCO2 = 'total-TCO2',
    TCO2HA = 'TCO2-ha',
}

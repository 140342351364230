import { TAC_FILES, ANNEX_FILES } from '@shared/constants/contract';
import { useTranslation } from 'react-i18next';

/**
 * @param countryIsoCode The country ISO code
 * @throws Error if no terms and conditions files or land owner approval files found for the country
 * @returns termsAndConditionsPdfLink and landownerApprovalPdfLink
 */
export const useGetTermsAndConditions = (countryIsoCode: string) => {
    const { i18n } = useTranslation();
    const language = i18n.language;

    if (!countryIsoCode) {
        return { termsAndConditionsPdfLink: '', landownerApprovalPdfLink: '' };
    }

    const termsAndConditionsPdfLink = '/terms-and-conditions/' + getTacFilePath(language, countryIsoCode);
    const landownerApprovalPdfLink = '/terms-and-conditions/' + getLandOwnerApprovalFilePath(language, countryIsoCode);

    return { termsAndConditionsPdfLink, landownerApprovalPdfLink };
};

const getTacFilePath = (language: string, countryIsoCode: string) => {
    const countryTaCFiles = TAC_FILES[countryIsoCode];

    if (!countryTaCFiles) {
        throw new Error(`No terms and conditions files found for country ${countryIsoCode}`);
    }

    return countryTaCFiles[language] ?? Object.values(countryTaCFiles)[0];
};

const getLandOwnerApprovalFilePath = (language: string, countryIsoCode: string) => {
    const countryAnnexFiles = ANNEX_FILES[countryIsoCode];

    if (!countryAnnexFiles) {
        throw new Error(`No land owner approval files found for country ${countryIsoCode}`);
    }

    return countryAnnexFiles[language] ?? Object.values(countryAnnexFiles)[0];
};

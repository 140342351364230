import { useGetResidueManagementQuery } from '@modules/results/api/summary.api';
import { pieChartGraphBuilder } from '@modules/results/utils/GraphBuilder/pieChartGraphBuilder';
import { residueManagementGraphTheme } from '@modules/results/utils/theme/residueManagementGraphTheme';
import { SharedStateT } from '@shared/store';
import * as echarts from 'echarts';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const useResidueManagementGraphData = () => {
    const { t } = useTranslation();
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSummarySeason.selectedSeasonId);
    const {
        data: residueManagementGraphData,
        isLoading: isLoadingResidueManagement,
        isFetching: isFetchingResidueManagement,
    } = useGetResidueManagementQuery({
        farmSeasonId: selectedSeasonId,
    });

    const legendFormatterFunction = useCallback(
        (name: string) => {
            const parts = name.split(',');
            return `{paddingBottom|${t(parts[0])}}\n${parts[1]} {grey|${parts[2]}}`;
        },
        [t],
    );

    const residueManagementGraphRef = useRef(null);

    useEffect(() => {
        if (residueManagementGraphData && residueManagementGraphRef.current) {
            const residueManagementGraph = echarts.init(
                residueManagementGraphRef.current,
                residueManagementGraphTheme,
                {
                    renderer: 'svg',
                },
            );
            const residueManagementGraphDataWithTranslation = residueManagementGraphData?.map((residue) => ({
                name: `${t(`results.summary.sequestration.residue-management.${residue?.slug}`)},${residue?.value}%,| ${
                    residue?.total_area
                } ${t(`constants.hectare-unit`)}`,
                label: t(`results.summary.sequestration.residue-management.${residue?.slug}`),
                ...residue,
            }));
            const options = pieChartGraphBuilder({
                data: residueManagementGraphDataWithTranslation,
                hasTooltip: true,
                tooltipTitle: t('results.summary.sequestration.residue-management'),
                tooltipUnit: t('constants.hectare-unit'),
                hasLegend: true,
                legendFormatter: legendFormatterFunction,
                tooltipTotal: residueManagementGraphData?.reduce((acc, item) => acc + Number(item.total_area), 0),
                chartId: 'residue-management-graph',
            });

            options.legend.left = '60%';
            options.legend.top = 100;

            residueManagementGraph.setOption({ ...options });

            return () => {
                residueManagementGraph.dispose();
            };
        }
    }, [residueManagementGraphData, t, legendFormatterFunction]);

    const hasNoData = useMemo(() => residueManagementGraphData?.length === 0, [residueManagementGraphData]);

    return {
        hasNoData,
        residueManagementGraphRef,
        isLoadingResidueManagement: isLoadingResidueManagement || isFetchingResidueManagement,
        t,
    };
};

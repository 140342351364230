import { Card, Quote } from '@soil-capital/ui-kit/components';
import { IconInfo } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { CarbonResultsGraph } from '../CarbonResultsGraph/CarbonResultsGraph';
import { useTranslation } from 'react-i18next';
import { useResultsEarningContentPageStyle } from './ResultsEarningContentPage.style';
import { FarmSeasonResultsPerCropV3T, FarmSeasonResultsV3T } from '@modules/results/api/results.types';
import { ResultsFaq } from '../ResultsFaq/ResultsFaq';
import { CarbonResultsPerCropGraph } from '../CarbonResultsPerCropGraph/CarbonResultsPerCropGraph';
import { useRef, useState } from 'react';

export type ResultsEarningContentPageT = {
    isAuditDone: boolean;
    results: FarmSeasonResultsV3T;
    harvestYear: number;
    resultsPerCrop?: FarmSeasonResultsPerCropV3T;
};

export const ResultsEarningContentPage = ({ isAuditDone, results, resultsPerCrop }: ResultsEarningContentPageT) => {
    const { t } = useTranslation();
    const { classes } = useResultsEarningContentPageStyle();
    const [openFaq, setOpenFaq] = useState(false);
    const faqRef = useRef<HTMLDivElement | null>(null);

    return (
        <>
            <div className={classes.header}>
                <Typography variant="h1">{t('results.earning.title')}</Typography>
                {!isAuditDone && (
                    <Card className={classes.disclaimerContainer}>
                        <IconInfo color="success" />
                        <Typography variant="body2">{t('results.earning.disclaimer')}</Typography>
                    </Card>
                )}
            </div>
            <Quote
                author={{
                    avatar: '/assets/images/agro-max-avatar.jpeg',
                    name: 'Max,',
                    role: t('results.introduction.quote.author.role'),
                }}
            >
                <Typography sx={{ mb: 1 }} variant="h3">
                    {results.ghg_balance > 0
                        ? t('results.earning.quote.emission-title')
                        : t('results.earning.quote.sequestration-title')}
                </Typography>
                {t('results.earning.quote.content')}
            </Quote>
            <CarbonResultsGraph results={results} faqRef={faqRef} setOpenFaq={setOpenFaq} />
            <CarbonResultsPerCropGraph resultsPerCrop={resultsPerCrop} />
            <ResultsFaq ref={faqRef} openFaq={openFaq} onClick={() => setOpenFaq(!openFaq)} />
        </>
    );
};

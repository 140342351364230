import { FarmSeasonResultsPerCropV3T, carbonUnits } from '@modules/results/api/results.types';
import { resultsBarGraphBuilder } from '@modules/results/utils/GraphBuilder/resultsBarGraphBuilder';
import { addGrassAnd0 } from '@modules/results/utils/addGrassAnd0';
import * as echarts from 'echarts';
import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useCarbonResultsPerCropGraph = ({ resultsPerCrop }: { resultsPerCrop?: FarmSeasonResultsPerCropV3T }) => {
    const carbonResultsPerCropGraphRef = useRef(null);
    const { t } = useTranslation();
    const [selectedUnit, setSelectedUnit] = useState<carbonUnits.TCO2 | carbonUnits.TCO2HA>(carbonUnits.TCO2HA);

    useEffect(() => {
        if (carbonResultsPerCropGraphRef.current && resultsPerCrop) {
            const formattedData: Record<string, Record<string, number>> = resultsPerCrop.reduce((acc, item) => {
                const translatedName = item.slug ? t(`db.crop.slug-${item.slug}`) : item.name;
                acc[translatedName] = {
                    [translatedName]:
                        selectedUnit === carbonUnits.TCO2
                            ? parseFloat((Number(item.total_ghg_balance) * Number(item.total_area)).toFixed(2))
                            : parseFloat(Number(item.total_ghg_balance).toFixed(2)),
                };
                return acc;
            }, {} as Record<string, Record<string, number>>);
            const carbonResultPerCropGraph = echarts.init(
                carbonResultsPerCropGraphRef.current,
                { color: ['#D89C58'] },
                {
                    renderer: 'svg',
                },
            );
            const defaultOptions = resultsBarGraphBuilder({
                data: formattedData,
                tooltipTitle: t('results.earning.graph.x-axis'),
                tooltipUnit: selectedUnit === carbonUnits.TCO2 ? t('constants.total-TCO2') : t('constants.TCO2-ha'),
            });
            carbonResultPerCropGraph.setOption({
                ...defaultOptions,
            });
            addGrassAnd0(carbonResultPerCropGraph);
            return () => {
                carbonResultPerCropGraph.dispose();
            };
        }
    }, [resultsPerCrop, t, selectedUnit]);

    return { carbonResultsPerCropGraphRef, setSelectedUnit, selectedUnit };
};

import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useGetResultsPerCropQuery } from '@modules/results/api/results.api';
import { carbonUnits } from '@modules/results/api/results.types';
import { resultsBarGraphBuilder } from '@modules/results/utils/GraphBuilder/resultsBarGraphBuilder';
import { addGrassAnd0 } from '@modules/results/utils/addGrassAnd0';
import { SharedStateT } from '@shared/store';
import * as echarts from 'echarts';
import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const useCarbonResultsSummaryPerCropGraph = () => {
    const carbonResultsPerCropGraphRef = useRef(null);
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const [selectedUnit, setSelectedUnit] = useState<carbonUnits.TCO2 | carbonUnits.TCO2HA>(carbonUnits.TCO2HA);
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSummarySeason.selectedSeasonId);
    const {
        data: resultsPerCrop,
        isLoading: isLoadingResultsPerCrop,
        isFetching: isFetchingResultsPerCrop,
    } = useGetResultsPerCropQuery({
        farmSeasonId: selectedSeasonId ?? currentSeasonId,
    });

    useEffect(() => {
        if (carbonResultsPerCropGraphRef.current && resultsPerCrop) {
            const formattedData: Record<string, Record<string, number>> = resultsPerCrop.reduce((acc, item) => {
                const translatedName = item.slug ? t(`db.crop.slug-${item.slug}`) : item.name;
                acc[translatedName] = {
                    [t('results.earning.graph-per-crop-summary.emissions')]:
                        selectedUnit === carbonUnits.TCO2
                            ? parseFloat((Number(item.total_emissions) * Number(item.total_area)).toFixed(2))
                            : parseFloat(Number(item.total_emissions).toFixed(2)),
                    [t('results.earning.graph-per-crop-summary.sequestrations')]:
                        selectedUnit === carbonUnits.TCO2
                            ? parseFloat((Number(item.total_sequestrations) * Number(item.total_area)).toFixed(2))
                            : parseFloat(Number(item.total_sequestrations).toFixed(2)),
                };
                return acc;
            }, {} as Record<string, Record<string, number>>);
            const carbonResultPerCropGraph = echarts.init(carbonResultsPerCropGraphRef.current, {
                renderer: 'svg',
            });
            const tooltipTitle = t('results.earning.graph.x-axis');
            const tooltipUnit = selectedUnit === carbonUnits.TCO2 ? t('constants.TCO2') : t('constants.TCO2-ha');
            const defaultOptions = resultsBarGraphBuilder({
                data: formattedData,
                tooltipTitle,
                tooltipUnit,
            });
            defaultOptions.series.forEach((serie) => {
                if (serie.type === 'bar') {
                    serie.data = serie.data.map((dataPoint) => {
                        return {
                            ...dataPoint,
                            itemStyle: {
                                ...dataPoint.itemStyle,
                                color: dataPoint.value >= 0 ? '#C14022' : '#84C19F',
                            },
                        };
                    });
                }
            });
            const totalPoints = resultsPerCrop.map((item) => ({
                name: item.slug ? t(`db.crop.slug-${item.slug}`) : item.name,
                value:
                    selectedUnit === carbonUnits.TCO2
                        ? parseFloat((Number(item.total_ghg_balance) * Number(item.total_area)).toFixed(2))
                        : parseFloat(Number(item.total_ghg_balance).toFixed(2)),
                itemStyle: {
                    color: '#D89C58',
                },
            }));
            const series = [
                ...defaultOptions.series,
                {
                    name: t('results.earning.graph-per-crop-summary.total'),
                    type: 'scatter',
                    data: [{}, ...totalPoints, {}],
                    symbolSize: 10,
                    z: 30,
                },
            ];
            carbonResultPerCropGraph.setOption({
                ...defaultOptions,
                series,
            });
            addGrassAnd0(carbonResultPerCropGraph);
            return () => {
                carbonResultPerCropGraph.dispose();
            };
        }
    }, [resultsPerCrop, t, selectedUnit]);

    return {
        carbonResultsPerCropGraphRef,
        setSelectedUnit,
        selectedUnit,
        isLoading: isLoadingResultsPerCrop || isFetchingResultsPerCrop,
    };
};

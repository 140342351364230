import * as echarts from 'echarts';
import { pieChartGraphBuilderType } from './graphBuilder.types';

export const pieChartGraphBuilder = ({
    data,
    hasLegend = false,
    legendFormatter,
    hasTooltip = false,
    tooltipTitle,
    tooltipUnit = '',
    labelFormatter,
    tooltipTotal,
    chartId,
}: pieChartGraphBuilderType) => {
    return {
        grid: {
            left: '10%',
            right: hasLegend ? '45%' : '10%',
            bottom: '10%',
            top: '10%',
            containLabel: true,
        },
        tooltip: {
            show: hasTooltip,
            trigger: 'item',
            backgroundColor: '#323F45',
            textStyle: {
                color: '#fff',
            },
            borderWidth: 0,
            padding: 0,
            appendToBody: true,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formatter: function (params: any) {
                const totalPercent = 100;
                const chartDom = document.getElementById(chartId);
                const colors = chartDom ? echarts.getInstanceByDom(chartDom)?.getOption()?.color || [] : [];
                const colorArray = Array.isArray(colors) ? colors : [];

                let html = `
                    <div style=" font-size: 12px; font-family: BrownStd-Bold, BrownStd-Regular, Roboto, Helvetica, Arial, sans-serif; color: #FFFFFF;">
                        <div style="padding: 12px 8px; text-align: left; font-size: 14px; text-transform: uppercase; color: #BFCBD1; font-weight: 700; margin-bottom: 12px;">
                            ${tooltipTitle || ''}
                        </div>
                        <div style="
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 8px;
                    ">
                `;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                data.forEach((item: any, index: number) => {
                    html += `
                    <div style="width: 100%; display: grid; grid-template-columns: auto 50px 50px; padding: 0px 12px;
                        gap: 8px; background-color: ${params.value === item.value ? '#45565F' : ''}">
                        <div style="text-align: left; display: flex; align-items: center; ">
                            <span style="display: inline-block; width: 12px; height: 12px; background-color: ${
                                colorArray[index] ?? '#000'
                            }; border-radius: 50%; margin-right: 10px;"></span>
                            ${item.label}
                        </div>
                        <div style="text-align: center;">${item.total_area} ${tooltipUnit || ''}</div>
                        <div style="text-align: right; color: #BFCBD1;">${item.value}%</div>
                        </div>
                    `;
                });

                html += `
                <div style="width: 100%; display: grid; grid-template-columns: auto 50px 50px; padding: 8px 12px;
                        gap: 8px;" />
                    <div style="padding-left:22px; padding-top: 2px; text-align: left; font-size: 14px;">Total</div>
                    <div style="padding-top: 2px; text-align: center;">${tooltipTotal} ${tooltipUnit || ''}</div>
                    <div style="padding-top: 2px; text-align: right; color: #BFCBD1;">${totalPercent}%</div>
                    </div>
                </div>`;

                return html;
            },
        },
        legend: {
            itemGap: 16,
            left: '45%',
            right: 'auto',
            top: 60,
            width: '350px',
            icon: 'circle',
            orient: 'vertical',
            formatter: legendFormatter,
            textStyle: {
                fontSize: 16,
                fontFamily: 'BrownStd-Regular, Roboto, Helvetica, Arial, sans-serif',
                color: '#323F45',
                rich: {
                    bold: {
                        fontSize: 16,
                        fontFamily: 'BrownStd-Bold',
                        fontWeight: 'bold',
                        color: '#323F45',
                    },
                    grey: {
                        color: '#98ABB4',
                        fontSize: 16,
                        fontFamily: 'BrownStd-Regular, Roboto, Helvetica, Arial, sans-serif',
                    },
                    paddingBottom: {
                        lineHeight: 30,
                        fontSize: 16,
                        fontFamily: 'BrownStd-Regular',
                        color: '#323F45',
                    },
                },
            },
        },
        series: [
            {
                type: 'pie',
                radius: ['40%', '70%'],
                center: ['25%', '50%'],
                avoidLabelOverlap: false,
                avoidLegendOverlap: true,
                itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 2,
                },
                label: {
                    show: !!labelFormatter,
                    position: 'center',
                    formatter: labelFormatter,
                    fontWeight: 'bold',
                    color: '#323F45',
                    textStyle: {
                        fontSize: 16,
                        fontFamily: 'BrownStd-Bold, Arial, sans-serif',
                    },
                },
                labelLine: {
                    show: false,
                },
                data: data,
            },
        ],
    };
};

import { formatBarGraphData } from '../formatBarGraphData';
import { basicBarGraphBuilderType } from './graphBuilder.types';

/**
 * Builds a configuration object for a stacked bar graph using ECharts.
 *
 * @param {object} params - The parameters for configuring the graph.
 * @param {object} params.data - The dataset for the graph.
 *   - Keys of the outer object represent categories on the x-axis (e.g., 'Xlabel').
 *   - Each inner object contains key-value pairs:
 *     - Keys are the names of the stacked items (shown in the legend).
 *     - Values are the numeric data points for each stacked item.
 * @returns {object} An ECharts option object for rendering a stacked bar graph.
 *
 * @example
 * // Example data input:
 * const data = {
 *   'Category1': { 'Item1': 10, 'Item2': 20, 'Item3': 30 },
 *   'Category2': { 'Item1': 15, 'Item2': 25, 'Item3': 35 },
 * };
 */
export const basicBarGraphBuilder = ({ data }: basicBarGraphBuilderType) => {
    const {
        yAxisMin,
        yAxisMax,
        formattedData,
        categories,
        names,
        items,
        lastPositiveItemPerCategory,
        lastNegativeItemPerCategory,
    } = formatBarGraphData(data);

    const series = items.map((item) => ({
        name: item,
        type: 'bar',
        stack: 'total',
        data: categories.map((category) => {
            const value = formattedData[category]?.[item] || 0;

            let borderRadius = [0, 0, 0, 0];
            const isLastPositive = value > 0 && item === lastPositiveItemPerCategory[category];
            const isLastNegative = value < 0 && item === lastNegativeItemPerCategory[category];

            if (isLastPositive) {
                borderRadius = [10, 10, 0, 0];
            } else if (isLastNegative) {
                borderRadius = [0, 0, 10, 10];
            }

            return {
                value,
                itemStyle: {
                    borderRadius,
                },
            };
        }),
        barWidth: '6px',
        z: 20,
    }));

    const graphOptions = {
        grid: {
            left: '0px',
            right: '0px',
            bottom: '0px',
            top: '0px',
            containLabel: true,
        },
        toolip: false,
        legend: false,
        xAxis: {
            nameLocation: 'center',
            type: 'category',
            data: categories,
            axisLine: {
                show: true,
                lineStyle: {
                    width: 2,
                    color: '#333',
                },
            },
            axisTick: { show: false },
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#ccc',
                },
            },
            axisLabel: {
                interval: 0,
                show: true,
                fontSize: 14,
                fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                color: '#323F45',
            },
            boundaryGap: false,
            z: 10,
        },
        yAxis: [
            // Main axis: standard labels and outside ticks
            {
                position: 'left',
                nameLocation: 'center',
                nameGap: 40,
                name: '',
                nameRotate: 90,
                nameTextStyle: {
                    fontSize: 14,
                    fontWeight: 'bold',
                    fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                    color: '#333',
                },
                type: 'value',
                offset: -45,
                min: yAxisMin + 0.1 * yAxisMin - 0.1 * yAxisMax,
                max: yAxisMax + 0.1 * yAxisMax - 0.1 * yAxisMin,
                axisLine: {
                    show: true,
                    lineStyle: {
                        width: 2,
                        color: '#333',
                    },
                },
                axisTick: {
                    show: true,
                    length: 7,
                    inside: false,
                    lineStyle: {
                        width: 2,
                        color: '#333',
                    },
                },
                axisLabel: {
                    show: true,
                    fontWeight: 'bold',
                    showMaxLabel: false,
                    showMinLabel: false,
                    margin: 12,
                    fontSize: 14,
                    fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                    color: '#323F45',
                    formatter: (value: number) => (value === 0 ? '' : value), // Hide label for 0
                },
                splitLine: {
                    show: false,
                },
                z: 10,
            },
            // Secondary axis: only inside ticks, no labels
            {
                position: 'left',
                type: 'value',
                offset: -45,
                min: yAxisMin + 0.1 * yAxisMin - 0.1 * yAxisMax,
                max: yAxisMax + 0.1 * yAxisMax - 0.1 * yAxisMin,
                axisLine: {
                    show: true,
                    lineStyle: {
                        width: 2,
                        color: '#333',
                    },
                },
                axisTick: {
                    show: true,
                    length: 7,
                    inside: true,
                    lineStyle: {
                        width: 2,
                        color: '#333',
                    },
                },
                axisLabel: {
                    show: false,
                    showMaxLabel: false,
                    showMinLabel: false,
                },
                splitLine: {
                    show: false,
                },
                z: 10,
            },
        ],
        series,
    };

    return {
        yAxisMin,
        yAxisMax,
        formattedData,
        categories,
        names,
        items,
        lastPositiveItemPerCategory,
        lastNegativeItemPerCategory,
        graphOptions,
    };
};

import { ECharts } from 'echarts';
import { grassSvg } from './grassSvg';

export function addGrassAnd0(chartInstance: ECharts) {
    const options = chartInstance.getOption() as echarts.EChartsOption;
    const grid = options.grid as echarts.GridComponentOption[];

    if (!grid || grid.length === 0) {
        return;
    }

    const gridLeft = parseFloat(grid[0].left as string) + 10;
    const yZeroPosition = chartInstance.convertToPixel({ yAxisIndex: 0 }, 0);
    const xZeroPosition = chartInstance.convertToPixel({ xAxisIndex: 0 }, 0);

    chartInstance.setOption({
        graphic: [
            {
                type: 'image',
                left: `${gridLeft + 100}px`,
                top: `${yZeroPosition - 20}px`,
                style: {
                    image: grassSvg,
                },
            },
            {
                type: 'text',
                left: `${xZeroPosition - 20}px`,
                top: `${yZeroPosition - 5}px`,
                style: {
                    text: '0',
                    font: 'bold 14px Arial',
                    fill: '#000',
                    align: 'center',
                    verticalAlign: 'middle',
                },
            },
        ],
    });
}

import api from '@shared/api';
import { FarmSeasonResultsPerCropV3T, FarmSeasonResultsV3T } from '@modules/results/api/results.types';

export const resultsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getResults: builder.query<FarmSeasonResultsV3T, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/results`,
                method: 'GET',
            }),
            providesTags: ['Results'],
        }),
        getResultsPerCrop: builder.query<FarmSeasonResultsPerCropV3T, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/results-per-crop`,
                method: 'GET',
            }),
            providesTags: ['Results'],
        }),
    }),
});

export const { useGetResultsQuery, useGetResultsPerCropQuery } = resultsApi;

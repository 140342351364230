import { IconArrowEmission, IconArrowRight, IconArrowSequestration } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { Trans, useTranslation } from 'react-i18next';
import { truncateDecimals } from '@shared/utils/truncateDecimals';
import { useResultsCalculationDetailStyles } from './ResultsCalculationDetail.style';
import { Button, Card } from '@soil-capital/ui-kit/components';
import { carbonUnits } from '@modules/results/api/results.types';
import { Dispatch, RefObject, SetStateAction } from 'react';

export const ResultsCalculationDetail = ({
    ghgBalance,
    selectedUnit,
    faqRef,
    openFaq,
    isNetEmitter,
}: {
    ghgBalance: number;
    selectedUnit: carbonUnits;
    faqRef?: RefObject<HTMLDivElement | null>;
    openFaq?: Dispatch<SetStateAction<boolean>>;
    isNetEmitter: boolean;
}) => {
    const { t } = useTranslation();
    const { classes } = useResultsCalculationDetailStyles();
    const handleScroll = () => {
        if (faqRef?.current && openFaq) {
            openFaq(true);
            faqRef?.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Card className={classes.container}>
            <div className={classes.HeadingContainer}>
                <div className={classes.flexbox}>
                    {isNetEmitter ? (
                        <IconArrowEmission color="warning" fontSize="large" />
                    ) : (
                        <IconArrowSequestration color="success" fontSize="large" />
                    )}

                    <Typography variant="body2" className={classes.heading}>
                        <Trans
                            i18nKey={
                                isNetEmitter
                                    ? t('results.earning.graph.legend.emitter.title')
                                    : t('results.earning.graph.legend.sequester.title')
                            }
                        />
                    </Typography>
                </div>
                <Typography variant="subcaption" color="GrayText" className={classes.ghg}>{`${truncateDecimals(
                    Math.abs(ghgBalance),
                    2,
                )} ${
                    selectedUnit === carbonUnits.TCO2HA ? t('constants.TCO2-ha') : t('constants.total-TCO2')
                }`}</Typography>
            </div>

            <Typography variant="body" textAlign="center" className={classes.description}>
                {isNetEmitter
                    ? t('results.earning.graph.legend.emitter.description')
                    : t('results.earning.graph.legend.sequester.description')}
            </Typography>
            <Button endIcon={<IconArrowRight />} onClick={handleScroll}>
                {t('results.earning.graph.legend.cta')}
            </Button>
        </Card>
    );
};

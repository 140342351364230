import { useTranslation } from 'react-i18next';
import { Button } from '@soil-capital/ui-kit';
import { useNavigate } from 'react-router';
import { useIsEncodingDone } from '@shared/hooks/useIsEncodingDone';
import { ReactNode } from 'react';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { IconArrowRight } from '@soil-capital/ui-kit/icons';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';

export const EncodingDoneButton = ({ fallbackButton }: { fallbackButton: ReactNode }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { currentSeason } = useCurrentSeason();
    const { isEncodingDone } = useIsEncodingDone();

    if (!currentSeason || currentSeason?.carbon_programme_year === null) return fallbackButton;

    const idParam =
        currentSeason.baseline_farm_season_id && currentSeason.carbon_programme_year < 0
            ? currentSeason.baseline_farm_season_id
            : currentSeason.id;

    const handleRedirect = () => navigate(`/${idParam}/${encodingRoutesConfig.ENCODING_DONE}`);

    return isEncodingDone ? (
        <Button sx={{ whiteSpace: 'nowrap' }} variant="contained" onClick={handleRedirect} endIcon={<IconArrowRight />}>
            {t('encoding-layout.nav.action.go-to-encoding-done')}
        </Button>
    ) : (
        fallbackButton
    );
};

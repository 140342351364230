import api from '@shared/api';
import { SeasonT } from '@shared/entities';

export const ROTATION_SOURCE_ENUM = {
    NO_FIELDS: 'no_fields',
    MANUAL: 'manual',
    IMPORT: 'import',
} as const;

export type RotationSourceT = {
    source: (typeof ROTATION_SOURCE_ENUM)[keyof typeof ROTATION_SOURCE_ENUM];
};

export const yoyApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getPreviousFarmSeason: builder.query<SeasonT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/yoy/previous`,
                method: 'GET',
            }),
            providesTags: ['Season'],
        }),
        getNextFarmSeason: builder.query<SeasonT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/yoy/next`,
                method: 'GET',
            }),
            providesTags: ['Season'],
        }),
        startNewSeason: builder.mutation<SeasonT, { currentFarmSeasonId: number }>({
            query: ({ currentFarmSeasonId }) => ({
                url: `/v3/farm-seasons/${currentFarmSeasonId}/yoy/start`,
                method: 'POST',
            }),
            invalidatesTags: ['Season'],
        }),
        getRotationSource: builder.query<RotationSourceT | null, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/yoy/rotation-source`,
                method: 'GET',
            }),
            providesTags: ['Field'],
        }),
        duplicateOperationsFromYear: builder.mutation<
            SeasonT,
            { farmSeasonId: number; body: { source_season_id: number; crop_ids: number[] } }
        >({
            query: ({ farmSeasonId, body }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/yoy/duplicate`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [
                'Progress',
                'FieldCrop',
                'Operations',
                'Operation',
                'HistoryDuplicationData',
                'DuplicationSeasonSource',
            ],
        }),
        getDuplicationSourceSeasons: builder.query<SeasonT[], { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/yoy/duplicate/source-seasons`,
                method: 'GET',
            }),
            providesTags: ['DuplicationSeasonSource'],
        }),
    }),
});

export const {
    useGetPreviousFarmSeasonQuery,
    useGetNextFarmSeasonQuery,
    useStartNewSeasonMutation,
    useGetRotationSourceQuery,
    useDuplicateOperationsFromYearMutation,
    useGetDuplicationSourceSeasonsQuery,
} = yoyApi;

export const grassSvg = `data:image/svg+xml;base64,${btoa(`
            <svg width="37" height="22" viewBox="0 0 37 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M33.4818 20.4941C33.4818 20.4941 32.6307 11.5207 33.4818 7.27051C33.4818 7.27051 33.4818 16.6329 34.7003 21.4998L33.4818 20.4941Z"
                fill="#6AA080"
            />
            <path
                d="M32.9121 20.3565C32.9121 20.3565 34.498 20.5002 36.5003 7.91113C36.5003 7.91113 35.8941 18.6624 34.498 20.4942L32.9121 20.3505V20.3565Z"
                fill="#6AA080"
            />
            <path
                d="M29.2402 20.5C29.2402 20.5 33.2751 8.51532 30.5618 0.5C30.5618 0.5 32.7195 14.0163 26.5 20.5H29.2402Z"
                fill="#6AA080"
            />
            <path
                d="M33.1631 20.7633C33.1631 20.7633 25.3254 15.244 25.6132 6.83936C25.6132 6.83936 26.4092 16.8304 36.0165 20.4999L33.1631 20.7573V20.7633Z"
                fill="#6AA080"
            />
            <path
                d="M16.0971 19.3655C16.0971 19.3655 15.4457 11.4377 16.0971 7.68359C16.0971 7.68359 16.0971 15.9559 17.0323 20.2547L16.0971 19.3655Z"
                fill="#2D7059"
            />
            <path
                d="M8.89437 18.7668C8.89437 18.7668 7.91729 10.8391 8.89437 7.08496C8.89437 7.08496 8.89437 15.3573 10.2971 19.656L8.89437 18.7668Z"
                fill="#80BD9B"
            />
            <path
                d="M15.8076 19.5282C15.8076 19.5282 17.1628 19.6561 18.8692 8.28223C18.8692 8.28223 18.3535 17.9987 17.1628 19.6561L15.8076 19.5282Z"
                fill="#B2D1BB"
            />
            <path
                d="M8.46011 18.9295C8.46011 18.9295 9.81534 19.0574 11.5217 7.68359C11.5217 7.68359 11.006 17.4001 9.81534 19.0574L8.46011 18.9295Z"
                fill="#2D7059"
            />
            <path
                d="M14.9599 19.656C14.9599 19.656 11.0063 9.97022 13.6661 3.49316C13.6661 3.49316 11.5505 14.4147 17.6447 19.656H14.9599Z"
                fill="#80BD9B"
            />
            <path
                d="M4.24038 20.5C4.24038 20.5 8.27584 9.71328 5.56097 2.5C5.56097 2.5 7.72039 14.663 1.5 20.5H4.24038Z"
                fill="#B2D1BB"
            />
            <path
                d="M8.39345 19.656C8.39345 19.656 1.47595 14.6742 1.73152 7.08496C1.73152 7.08496 2.43293 16.1057 10.9094 19.4203L8.39061 19.6532L8.39345 19.656Z"
                fill="#2D7059"
            />
            <path
                d="M20.7061 19.546C20.7061 19.546 21.3964 19.297 23.1553 12.4727C23.1553 12.4727 22.6384 19.3682 21.0875 20.8534L20.7061 19.549V19.546Z"
                fill="#80BD9B"
            />
            <path
                d="M0.5 19.0772C0.5 19.0772 1.18672 18.9992 4.5 12.5C4.5 12.5 2.2843 19.383 0.511741 20.5L0.5 19.0741V19.0772Z"
                fill="#80BD9B"
            />
        </svg>`)}`;

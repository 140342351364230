import { IconRye } from '@soil-capital/ui-kit/icons';
import { GraphContainer } from '../../shared/GraphContainer/GraphContainer';
import { Toggle } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { carbonUnits } from '@modules/results/api/results.types';
import { useCarbonResultsSummaryPerCropStyles } from './useCarbonResultsSummaryPerCrop.style';
import { useCarbonResultsSummaryPerCropGraph } from './useCarbonResultsSummaryPerCropGraph';

export const CarbonResultsSummaryPerCropGraph = () => {
    const { t } = useTranslation();
    const { classes } = useCarbonResultsSummaryPerCropStyles();
    const { carbonResultsPerCropGraphRef, setSelectedUnit, selectedUnit, isLoading } =
        useCarbonResultsSummaryPerCropGraph();

    return (
        <div className={classes.padding}>
            <GraphContainer
                titleIcon={<IconRye fontSize="large" />}
                title={t('results.earning.graph-per-crop.title')}
                isLoading={isLoading}
                titleEnd={
                    <Toggle
                        labelLeft={t('constants.TCO2-ha')}
                        labelRight={t('constants.total-TCO2')}
                        isChecked={selectedUnit === carbonUnits.TCO2}
                        onChange={() => {
                            setSelectedUnit((prev) =>
                                prev === carbonUnits.TCO2 ? carbonUnits.TCO2HA : carbonUnits.TCO2,
                            );
                        }}
                        className={classes.toggle}
                    />
                }
            >
                <div className={classes.resultsContainer}>
                    <div ref={carbonResultsPerCropGraphRef} className={classes.graphContainer}></div>
                </div>
            </GraphContainer>
        </div>
    );
};

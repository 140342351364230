import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateFarmSeasonPreparationMutation } from '../../api/preparationApi';
import { useEncodingDoneContentData } from '@modules/encoding/pages/useEncodingDoneContentData';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';
import {
    useCreateFarmSeasonProfileMutation,
    useGetFarmSeasonProfileQuery,
} from '@modules/encoding/modules/farmProfile/api/farmProfileApi';

export const usePreparationContentPageLogic = () => {
    const { seasonId } = useParams();
    // We know from encoding guard that this param exists
    const farmSeasonId = Number(seasonId as string);
    const { currentSeason, currentSeasonLoading } = useCurrentSeason();
    const { harvestYear, isLoading: isLoadingHarvestYear } = useGetCurrentHarvestYear();
    const { data: profile, isLoading: isLoadingProfile } = useGetFarmSeasonProfileQuery({ farmSeasonId: farmSeasonId });
    const [createMutation, { isLoading: isUpdatingPreparationProgress }] = useCreateFarmSeasonPreparationMutation();
    const [createProfileMutation, { isLoading: isCreatingProfile }] = useCreateFarmSeasonProfileMutation();
    const { getStepProgress } = useEncodingDoneContentData();
    const { isBaseline, baselineHarvestYear } = useBaselineFarmSeason();

    const hasProfile = !!profile && !!profile.id;
    const programYear = currentSeason?.carbon_programme_year;
    const isLoading = isLoadingProfile || currentSeasonLoading || isLoadingHarvestYear;
    const isProfileReady = !isCreatingProfile && !isUpdatingPreparationProgress;

    const isYoYFarmSeason = !!currentSeason?.carbon_programme_year && currentSeason.carbon_programme_year > 0;

    const navigate = useNavigate();

    const handleGoNext = async () => {
        if (getStepProgress('preparation').current < getStepProgress('preparation').max) {
            await createMutation({ farmSeasonId: farmSeasonId });
        }

        if (!hasProfile) {
            await createProfileMutation({
                farmSeasonId: farmSeasonId,
                payload: {
                    static_data_ids: [],
                    has_agroforestry: null,
                    has_drained_fields: null,
                    has_inter_crop_cover: null,
                    has_irrigated_fields: null,
                    has_livestock: null,
                },
            });
        }

        navigate(`../${encodingRoutesConfig.FARM_PROFILE}`);
    };

    return {
        isYoYFarmSeason,
        isBaseline,
        harvestYear,
        isProfileReady,
        isLoading,
        handleGoNext,
        programYear,
        baselineHarvestYear,
    };
};

import { makeStyles } from '@soil-capital/ui-kit/style';

export const useCarbonResultsSummaryPerCropStyles = makeStyles()((theme) => ({
    padding: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    resultsContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: `${theme.spacing(3)} 0`,
        gap: theme.spacing(3),
    },
    graphContainer: {
        width: '100%',
        height: '400px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    toggle: { justifySelf: 'flex-end' },
}));

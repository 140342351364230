import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';
import { BaseGuard, GuardT } from '@shared/guards/BaseGuard';

export const useIsNotHistorical = () => {
    const { isHistoryFarmSeason, isLoading } = useBaselineFarmSeason();

    if (isLoading) return undefined;
    return !isHistoryFarmSeason;
};

export const IsNotHistoricalGuard = (props: GuardT) => {
    const passed = useIsNotHistorical();

    return <BaseGuard passed={passed} {...props} />;
};

import { useCarbonResultsGraphStyles } from './CarbonResultsGraph.style';
import { useCarbonResultsGraph } from './useCarbonResultsGaph';
import { ResultsCalculationDetail } from '../ResultsCalculationDetail/ResultsCalculationDetail';
import { Toggle } from '@soil-capital/ui-kit/components';
import { GraphContainer } from '../../shared/GraphContainer/GraphContainer';
import { IconCarbon } from '@soil-capital/ui-kit/icons';
import { FarmSeasonResultsV3T, carbonUnits } from '@modules/results/api/results.types';
import { useTranslation } from 'react-i18next';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';

export const CarbonResultsGraph = ({
    results,
    faqRef,
    setOpenFaq,
}: {
    results: FarmSeasonResultsV3T;
    faqRef?: MutableRefObject<HTMLDivElement | null>;
    setOpenFaq?: Dispatch<SetStateAction<boolean>>;
}) => {
    const { classes } = useCarbonResultsGraphStyles();
    const { t } = useTranslation();
    const { carbonResultsGraphRef, setSelectedUnit, getSeasonNameByYear, ghgValue, selectedUnit } =
        useCarbonResultsGraph({
            results,
        });

    return (
        <GraphContainer
            titleIcon={<IconCarbon fontSize="large" />}
            title={t('results.earning.graph.title', {
                historyYear2: getSeasonNameByYear(-2),
                historyYear1: getSeasonNameByYear(-1),
                baselineYear: getSeasonNameByYear(0),
            })}
            titleEnd={
                <Toggle
                    labelLeft={t('constants.TCO2-ha')}
                    labelRight={t('constants.total-TCO2')}
                    isChecked={selectedUnit === carbonUnits.TCO2}
                    onChange={() =>
                        setSelectedUnit((prev) => (prev === carbonUnits.TCO2 ? carbonUnits.TCO2HA : carbonUnits.TCO2))
                    }
                    className={classes.toggle}
                />
            }
        >
            <div className={classes.resultsContainer}>
                <div ref={carbonResultsGraphRef} className={classes.graphContainer} />
                <ResultsCalculationDetail
                    isNetEmitter={results.ghg_balance > 0}
                    ghgBalance={ghgValue ?? 0}
                    selectedUnit={selectedUnit}
                    faqRef={faqRef}
                    openFaq={setOpenFaq}
                />
            </div>
        </GraphContainer>
    );
};

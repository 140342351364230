import { HelpBox, Stack } from '@soil-capital/ui-kit';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { NavigateFunction } from 'react-router-dom';
import { useIntroductionContentData } from './useIntroductionContentData';
import { useTranslation } from 'react-i18next';
import { ContentPage } from '@modules/encoding/shared/components/ContentPage/ContentPage';
import { IntroProgressLayout } from '@modules/encoding/shared/components/IntroProgressLayout/IntroProgressLayout';
import { IntroProgressItem } from '@modules/encoding/shared/components/IntroProgressItem/IntroProgressItem';
import { EncodingDoneButton } from '@modules/encoding/shared/components/EncodingDoneButton/EncodingDoneButton';

export const IntroductionContentPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();

    const {
        fieldDataList,
        isDataLoading,
        currentSoilAnalysisEncodingProgress,
        maxSoilAnalysisEncodingProgress,
        handleContinueClicked,
        handleClickOnField,
    } = useIntroductionContentData({ navigate });

    return (
        <ContentPage
            Title={<ContentPage.Title>{t('encoding-soil-analysis.introduction.title')}</ContentPage.Title>}
            Image={<ContentPage.Image src="/assets/images/farm-tractor-field.webp" />}
            Action={
                <EncodingDoneButton
                    fallbackButton={
                        <ContentPage.Action onClick={handleContinueClicked}>
                            {t('constants.continue')}
                        </ContentPage.Action>
                    }
                />
            }
            isLoading={isDataLoading}
        >
            <IntroProgressLayout
                title={t('encoding-soil-analysis.introduction.progress-title')}
                leftSection={
                    <Stack spacing={1} mt={1}>
                        <IntroProgressItem
                            text={t('encoding-soil-analysis.introduction.soil-analysis-progress')}
                            progress={{
                                max: maxSoilAnalysisEncodingProgress,
                                current: currentSoilAnalysisEncodingProgress,
                            }}
                        >
                            {fieldDataList.length > 0 && (
                                <Stack spacing={1} mt={1}>
                                    {fieldDataList.map((fieldData) => (
                                        <IntroProgressItem
                                            key={fieldData.id}
                                            text={fieldData.name}
                                            infoText={fieldData.infoText}
                                            progress={{
                                                max: fieldData.maxFieldSoilAnalysis,
                                                current: fieldData.currentFieldSoilAnalysis,
                                            }}
                                            image={fieldData.image}
                                            onClick={() => handleClickOnField(fieldData.id)}
                                        />
                                    ))}
                                </Stack>
                            )}
                        </IntroProgressItem>

                        {/* <IntroProgressItem
                            text={t('encoding-soil-analysis.introduction.soil-analysis-import-progress')}
                            progress={{
                                max: maxSoilAnalysisImportProgress,
                                current: currentSoilAnalysisImportProgress,
                            }}
                        /> */}
                    </Stack>
                }
                rightSection={
                    <HelpBox>
                        <Typography variant="h3">{t('encoding-soil-analysis.introduction.help-title')}</Typography>
                        {t('encoding-soil-analysis.introduction.help-subtitle-1', '') && (
                            <Typography variant="body" component="h4" mt={2}>
                                {t('encoding-soil-analysis.introduction.help-subtitle-1')}
                            </Typography>
                        )}
                        {t('encoding-soil-analysis.introduction.help-description-1', '') && (
                            <Typography variant="subcaption" color="neutral">
                                {t('encoding-soil-analysis.introduction.help-description-1')}
                            </Typography>
                        )}

                        {t('encoding-soil-analysis.introduction.help-subtitle-2', '') && (
                            <Typography variant="body" component="h4" mt={2}>
                                {t('encoding-soil-analysis.introduction.help-subtitle-2')}
                            </Typography>
                        )}
                        {t('encoding-soil-analysis.introduction.help-description-2', '') && (
                            <Typography variant="subcaption" color="neutral">
                                {t('encoding-soil-analysis.introduction.help-description-2')}
                            </Typography>
                        )}

                        {t('encoding-soil-analysis.introduction.help-subtitle-3', '') && (
                            <Typography variant="body" component="h4" mt={2}>
                                {t('encoding-soil-analysis.introduction.help-subtitle-3')}
                            </Typography>
                        )}
                        {t('encoding-soil-analysis.introduction.help-description-3', '') && (
                            <Typography variant="subcaption" color="neutral">
                                {t('encoding-soil-analysis.introduction.help-description-3')}
                            </Typography>
                        )}
                    </HelpBox>
                }
            />
        </ContentPage>
    );
};

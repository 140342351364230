import { basicBarGraphBuilder } from './basicBarGraphBuilder';
import { summaryBarGraphBuilderType } from './graphBuilder.types';

/**
 * Builds a configuration object for a stacked bar graph using ECharts.
 *
 * @param {object} params - The parameters for configuring the graph.
 * @param {object} params.data - The dataset for the graph.
 *   - Keys of the outer object represent categories on the x-axis (e.g., 'Xlabel').
 *   - Each inner object contains key-value pairs:
 *     - Keys are the names of the stacked items (shown in the legend).
 *     - Values are the numeric data points for each stacked item.
 * @param {string} [params.yTitle] - (Optional) Title for the y-axis.
 * @param {boolean} [params.hasLegend=false] - (Optional) Whether to display a legend.
 * @param {boolean} [params.hasTooltip=false] - (Optional) Whether to enable tooltips.
 * @param {string} [params.tooltipTitle] - (Optional) Title for the tooltip.
 * @param {string} [params.tooltipUnit=''] - (Optional) Unit displayed in tooltips.
 * @returns {object} An ECharts option object for rendering a stacked bar graph.
 *
 * @example
 * // Example data input:
 * const data = {
 *   'Category1': { 'Item1': 10, 'Item2': 20, 'Item3': 30 },
 *   'Category2': { 'Item1': 15, 'Item2': 25, 'Item3': 35 },
 * };
 *
 * // Generating options for the chart:
 * const options = summaryBarGraphBuilder({
 *   data,
 *   yTitle: 'Values',
 *   hasLegend: true,
 *   hasTooltip: true,
 *   tooltipTitle: 'Example Tooltip',
 *   tooltipUnit: '%',
 * });
 *
 * // Using ECharts to render the chart:
 * const chart = echarts.init(document.getElementById('chart-container'));
 * chart.setOption(options);
 */

export const summaryBarGraphBuilder = ({
    data,
    yTitle,
    hasTooltip = false,
    tooltipTitle,
    tooltipUnit = '',
    hasLegend = false,
    tooltipTotal,
}: summaryBarGraphBuilderType) => {
    const { graphOptions, items, names } = basicBarGraphBuilder({
        data,
    });

    graphOptions.series.forEach((serie) => {
        serie.barWidth = '41px';
    });
    graphOptions.yAxis[0].name = yTitle;
    graphOptions.yAxis[0].min = 0;
    graphOptions.yAxis[1].min = 0;
    graphOptions.yAxis[0].offset = 0;
    graphOptions.yAxis[1].offset = 0;

    return {
        ...graphOptions,
        grid: {
            left: '20%',
            right: hasLegend ? '45%' : '10%',
            bottom: '10%',
            top: '10%',
        },
        tooltip: {
            show: hasTooltip,
            trigger: 'axis',
            backgroundColor: '#323F45',
            textStyle: {
                color: '#fff',
            },
            borderWidth: 0,
            appendToBody: true,
            axisPointer: {
                type: 'none',
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formatter: function (params: any) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const total = params.reduce((sum: any, item: any) => sum + item.value, 0).toFixed(2);
                const totalPercent = 100;

                // Construction du contenu HTML
                let html = `
                    <div style="padding: 4px 8px; font-size: 12px; font-family: BrownStd-Bold, BrownStd-Regular, Roboto, Helvetica, Arial, sans-serif; color: #FFFFFF;">
                        <div style="text-align: left; font-size: 14px; text-transform: uppercase; color: #BFCBD1; font-weight: 700; margin-bottom: 12px;">
                            ${tooltipTitle || ''}
                        </div>
                        <div style="
                        display: grid;
                        grid-template-columns: auto 50px 50px;
                        gap: 8px;
                    ">
                `;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                params.reverse().forEach((item: any) => {
                    const percent = ((item.value / total) * 100).toFixed(1);
                    html += `
                        <div style="text-align: left; display: flex; align-items: center; ">
                            <span style="display: inline-block; width: 12px; height: 12px; background-color: ${
                                item.color
                            }; border-radius: 50%; margin-right: 10px;"></span>
                            ${item.seriesName}
                        </div>
                        <div style="text-align: center;">${item.value} ${tooltipUnit || ''}</div>
                        <div style="text-align: right; color: #BFCBD1;">${percent}%</div>
                    `;
                });

                html += `
                    <div style="padding-left:22px; padding-top: 2px; text-align: left; font-size: 14px;">Total</div>
                    <div style="padding-top: 2px; text-align: center;">${tooltipTotal ?? Number(total).toFixed(2)} ${
                    tooltipUnit || ''
                }</div>
                    <div style="padding-top: 2px; text-align: right; color: #BFCBD1;">${totalPercent}%</div>
                </div>`;

                return html;
            },
        },
        legend: {
            show: hasLegend,
            orient: 'vertical',
            icon: 'circle',
            right: 'auto',
            left: '45%',
            top: '10%',
            itemGap: 16,
            textStyle: {
                fontSize: 14,
                fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                color: '#323F45',
                rich: {
                    grey: {
                        color: '#98ABB4',
                        fontSize: 16,
                        fontFamily: 'BrownStd-Regular, Roboto, Helvetica, Arial, sans-serif',
                        lineHeight: 20,
                    },
                },
            },
            selectedMode: false,
            data: items.reverse(),
            formatter: (name: string) => {
                const item = names.find((n) => n.startsWith(name));
                const value = item?.split('|')[1]?.trim();
                return value ? `${name}{grey| \n| ${value}}` : name;
            },
        },
    };
};
